import { createSlice } from '@reduxjs/toolkit';
import { PlaybackRateType } from '@wearemojo/ui-components';
import { UnistylesRuntime } from 'react-native-unistyles';

import { AppState, SettingsState, ThemeSetting } from './types';

const DEFAULT_THEME = UnistylesRuntime.themeName;

const initialState: SettingsState = {
	theme: undefined,
	playbackSpeed: 1,
	userName: '',
};

const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		setTheme: (state, action: { payload: ThemeSetting }) => {
			state.theme = action.payload;
		},
		setPlaybackSpeed: (state, action: { payload: PlaybackRateType }) => {
			state.playbackSpeed = action.payload;
		},
		setUserName: (state, action: { payload: string }) => {
			state.userName = action.payload;
		},
	},
});

export const { setTheme, setPlaybackSpeed, setUserName } =
	settingsSlice.actions;
export const selectTheme = (state: AppState) =>
	state.settings.theme ?? DEFAULT_THEME;
export const selectPlaybackSpeed = (state: AppState) =>
	state.settings.playbackSpeed;
export const selectUserName = (state: AppState) => state.settings.userName;

export default settingsSlice;
