import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { createApi, retry } from '@reduxjs/toolkit/query/react';
import type { RunMessage, ServiceParams } from '@wearemojo/api-client';
import {
	RequestOptions,
	Service,
	ServiceResponses,
} from '@wearemojo/api-client';
import Cher from '@wearemojo/cher';
import {
	AudioPornType,
	EfficacyQuestionSetVersionType,
	ErrorMessagesType,
	MeditationType,
	PollType,
	SoundSeriesType,
	TherapySessionType,
} from '@wearemojo/sanity-schema';
import { Document } from '@wearemojo/sanity-schema/schema/_types';

import ApiClients, { ApiClient } from './ApiClients';
import ApiTag from './ApiTag';
import mojoQuery, { MojoQueryArgs } from './providers/mojo/mojoBaseQuery';
import {
	AUDIO_PORN_QUERY,
	EFFICACY_QUESTION_SET_QUERY,
	ERROR_MESSAGES_QUERY,
	EXPRESSION_DOCUMENTS_QUERY,
	FLOW_DOCUMENTS_QUERY,
	FRAGMENT_DOCUMENTS_QUERY,
	MEDITATIONS_QUERY,
	POLL_QUERY,
	SOUND_SERIES_QUERY,
	THERAPY_SESSIONS_QUERY,
} from './providers/sanity/queries';
import sanityQuery, {
	SanityQueryArgs,
} from './providers/sanity/sanityBaseQuery';
import { ExtraOptions, QueryArgs, QueryProvider } from './types';
import { retryCondition } from './utils';

type CustomBaseQueryFn = BaseQueryFn<QueryArgs, any, Cher, ExtraOptions>;

const customBaseQuery: CustomBaseQueryFn = async (queryArgs) => {
	const { provider, ...args } = queryArgs;
	// @TODO: implement global timeout (Promise.race + setTimeout?)

	if (provider === QueryProvider.Sanity) {
		const client = ApiClients.getClient(ApiClient.sanity);
		return sanityQuery(client, args as SanityQueryArgs);
	}

	if (provider === QueryProvider.Mojo) {
		const client = ApiClients.getClient(ApiClient.mojo);
		return mojoQuery(client, args as MojoQueryArgs);
	}

	throw new Error(`Provider "${provider}" not supported/implemented`);
};

const apiSlice = createApi({
	reducerPath: 'api',
	refetchOnReconnect: true,
	baseQuery: retry(customBaseQuery, { retryCondition }) as CustomBaseQueryFn,
	tagTypes: Object.values(ApiTag),
	endpoints: (builder) => {
		return {
			getPollContent: builder.query<PollType[], void>({
				providesTags: [ApiTag.Sanity],
				query: createSanityQuery(POLL_QUERY),
				extraOptions: { retryOnError: true },
			}),
			getErrorMessages: builder.query<ErrorMessagesType, void>({
				providesTags: [ApiTag.Sanity],
				query: createSanityQuery(ERROR_MESSAGES_QUERY),
				extraOptions: { retryOnError: true },
			}),
			getSoundSeries: builder.query<SoundSeriesType[], void>({
				providesTags: [ApiTag.Sanity],
				query: createSanityQuery(SOUND_SERIES_QUERY),
				extraOptions: { retryOnError: true },
			}),
			getTherapySessions: builder.query<TherapySessionType, void>({
				providesTags: [ApiTag.Sanity],
				query: createSanityQuery(THERAPY_SESSIONS_QUERY),
				extraOptions: { retryOnError: true },
			}),
			getAudioPorn: builder.query<AudioPornType, void>({
				providesTags: [ApiTag.Sanity],
				query: createSanityQuery(AUDIO_PORN_QUERY),
				extraOptions: { retryOnError: true },
			}),
			getMeditations: builder.query<MeditationType, void>({
				providesTags: [ApiTag.Sanity],
				query: createSanityQuery(MEDITATIONS_QUERY),
				extraOptions: { retryOnError: true },
			}),
			getExpressionDocuments: builder.query<Document[], void>({
				providesTags: [ApiTag.Sanity],
				query: createSanityQuery(EXPRESSION_DOCUMENTS_QUERY),
				extraOptions: { retryOnError: true },
			}),
			getFragmentDocuments: builder.query<Document[], void>({
				providesTags: [ApiTag.Sanity],
				query: createSanityQuery(FRAGMENT_DOCUMENTS_QUERY),
				extraOptions: { retryOnError: true },
			}),
			getFlowDocuments: builder.query<Document[], void>({
				providesTags: [ApiTag.Sanity],
				query: createSanityQuery(FLOW_DOCUMENTS_QUERY),
				extraOptions: { retryOnError: true },
			}),
			getEfficacyQuestionSet: builder.query<
				EfficacyQuestionSetVersionType,
				{ version: string }
			>({
				providesTags: [ApiTag.Sanity],
				query: createSanityQuery(EFFICACY_QUESTION_SET_QUERY),
				extraOptions: { retryOnError: true },
			}),
			ping: builder.query<void, void>({
				query: createMojoQuery(Service.ping, 'ping'),
				extraOptions: { retryOnError: true },
			}),
			getContentValidation: builder.query<
				ServiceResponses[Service.contentvalidation]['validateProgram'],
				void
			>({
				providesTags: [ApiTag.Sanity],
				query: createMojoQuery(Service.contentvalidation, 'validateProgram'),
				extraOptions: { retryOnError: true },
			}),
			getAuthUser: builder.query<
				ServiceResponses[Service.authuser]['getUser'],
				ServiceParams[Service.authuser]['getUser']
			>({
				query: createMojoQuery(Service.authuser, 'getUser'),
				extraOptions: { retryOnError: true },
			}),
			listUserActivity: builder.query<
				ServiceResponses[Service.learntracking]['listUserActivity'],
				ServiceParams[Service.learntracking]['listUserActivity']
			>({
				providesTags: [ApiTag.LearnTracking],
				query: createMojoQuery(Service.learntracking, 'listUserActivity'),
				extraOptions: { retryOnError: true },
			}),
			resetAllProgress: builder.mutation<
				ServiceResponses[Service.learntracking]['resetAllProgress'],
				ServiceParams[Service.learntracking]['resetAllProgress']
			>({
				invalidatesTags: [
					ApiTag.DailyChallenge,
					ApiTag.DailyTask,
					ApiTag.LearnTracking,
				],
				query: createMojoQuery(Service.learntracking, 'resetAllProgress'),
			}),
			recordActivityLearningCompleted: builder.mutation<
				ServiceResponses[Service.learntracking]['recordActivityLearningCompleted'],
				ServiceParams[Service.learntracking]['recordActivityLearningCompleted']
			>({
				invalidatesTags: [ApiTag.LearnTracking, ApiTag.DailyTask],
				query: createMojoQuery(
					Service.learntracking,
					'recordActivityLearningCompleted',
				),
			}),
			recordActivityCompleted: builder.mutation<
				ServiceResponses[Service.learntracking]['recordActivityCompleted'],
				ServiceParams[Service.learntracking]['recordActivityCompleted']
			>({
				invalidatesTags: [
					ApiTag.DailyChallenge,
					ApiTag.DailyTask,
					ApiTag.LearnTracking,
				],
				query: createMojoQuery(
					Service.learntracking,
					'recordActivityCompleted',
				),
			}),
			getChallenges: builder.query<
				ServiceResponses[Service.dailychallenge]['getChallenges'],
				ServiceParams[Service.dailychallenge]['getChallenges']
			>({
				providesTags: [ApiTag.DailyChallenge],
				query: createMojoQuery(Service.dailychallenge, 'getChallenges'),
			}),
			acceptChallenge: builder.mutation<
				ServiceResponses[Service.dailychallenge]['acceptChallenge'],
				ServiceParams[Service.dailychallenge]['acceptChallenge']
			>({
				invalidatesTags: [ApiTag.DailyChallenge, ApiTag.LearnTracking],
				query: createMojoQuery(Service.dailychallenge, 'acceptChallenge'),
			}),
			declineChallenge: builder.mutation<
				ServiceResponses[Service.dailychallenge]['declineChallenge'],
				ServiceParams[Service.dailychallenge]['declineChallenge']
			>({
				invalidatesTags: [ApiTag.DailyChallenge, ApiTag.LearnTracking],
				query: createMojoQuery(Service.dailychallenge, 'declineChallenge'),
			}),
			getFeaturesRaw: builder.query<
				ServiceResponses[Service.featureconfig]['getFeaturesRaw'],
				ServiceParams[Service.featureconfig]['getFeaturesRaw']
			>({
				providesTags: [ApiTag.FeatureConfig],
				query: createMojoQuery(Service.featureconfig, 'getFeaturesRaw', {
					disableResponseDesnek: true,
				}),
				extraOptions: { retryOnError: true },
			}),
			isStaffMember: builder.query<
				ServiceResponses[Service.staff]['isStaffMember'],
				ServiceParams[Service.staff]['isStaffMember']
			>({
				query: createMojoQuery(Service.staff, 'isStaffMember'),
				extraOptions: { retryOnError: true },
			}),
			getUserPreferences: builder.query<
				ServiceResponses[Service.userpreference]['getPreferences'],
				ServiceParams[Service.userpreference]['getPreferences']
			>({
				query: createMojoQuery(Service.userpreference, 'getPreferences'),
				extraOptions: { retryOnError: true },
			}),
			getPseudonym: builder.query<
				ServiceResponses[Service.pseudonym]['getPseudonym'],
				ServiceParams[Service.pseudonym]['getPseudonym']
			>({
				providesTags: [ApiTag.Pseudonym],
				query: createMojoQuery(Service.pseudonym, 'getPseudonym'),
				extraOptions: { retryOnError: true },
			}),
			recordConsent: builder.mutation<
				ServiceResponses[Service.pseudonym]['recordConsent'],
				ServiceParams[Service.pseudonym]['recordConsent']
			>({
				invalidatesTags: [ApiTag.EfficacyMeasurement, ApiTag.Pseudonym],
				query: createMojoQuery(Service.pseudonym, 'recordConsent'),
			}),
			getSubscriptionStatus: builder.query<
				ServiceResponses[Service.subscriptionstatus]['getSubscriptionStatus'],
				ServiceParams[Service.subscriptionstatus]['getSubscriptionStatus']
			>({
				providesTags: [ApiTag.SubscriptionStatus],
				query: createMojoQuery(
					Service.subscriptionstatus,
					'getSubscriptionStatus',
				),
				extraOptions: { retryOnError: true },
			}),
			getCurrentSubscription: builder.query<
				ServiceResponses[Service.subscriptionstatus]['getCurrentSubscription'],
				ServiceParams[Service.subscriptionstatus]['getCurrentSubscription']
			>({
				providesTags: [ApiTag.SubscriptionStatus],
				query: createMojoQuery(
					Service.subscriptionstatus,
					'getCurrentSubscription',
				),
				extraOptions: { retryOnError: true },
			}),
			getLatestInvoiceUrl: builder.query<
				ServiceResponses[Service.subscriptionstatus]['getLatestInvoiceUrl'],
				ServiceParams[Service.subscriptionstatus]['getLatestInvoiceUrl']
			>({
				providesTags: [ApiTag.SubscriptionStatus],
				query: createMojoQuery(
					Service.subscriptionstatus,
					'getLatestInvoiceUrl',
				),
				extraOptions: { retryOnError: true },
			}),
			getBillingPortalUrl: builder.query<
				ServiceResponses[Service.subscriptionstatus]['getBillingPortalUrl'],
				ServiceParams[Service.subscriptionstatus]['getBillingPortalUrl']
			>({
				providesTags: [ApiTag.SubscriptionStatus],
				query: createMojoQuery(
					Service.subscriptionstatus,
					'getBillingPortalUrl',
				),
				extraOptions: { retryOnError: true },
			}),
			getTasks: builder.query<
				ServiceResponses[Service.dailytask]['getTasks'],
				ServiceParams[Service.dailytask]['getTasks']
			>({
				providesTags: [ApiTag.DailyTask],
				query: createMojoQuery(Service.dailytask, 'getTasks'),
				extraOptions: { retryOnError: true },
			}),
			invalidateTasks: builder.mutation<
				ServiceResponses[Service.dailytask]['invalidateTasks'],
				ServiceParams[Service.dailytask]['invalidateTasks']
			>({
				invalidatesTags: [ApiTag.DailyTask],
				query: createMojoQuery(Service.dailytask, 'invalidateTasks'),
			}),
			listDateStates: builder.query<
				ServiceResponses[Service.dailytask]['listDateStates'],
				ServiceParams[Service.dailytask]['listDateStates']
			>({
				providesTags: [ApiTag.DailyTask],
				query: createMojoQuery(Service.dailytask, 'listDateStates'),
				extraOptions: { retryOnError: true },
			}),
			getCurrentCoinBalance: builder.query<
				ServiceResponses[Service.dailytask]['getCurrentCoinBalance'],
				ServiceParams[Service.dailytask]['getCurrentCoinBalance']
			>({
				providesTags: [ApiTag.DailyTask, ApiTag.LearnTracking],
				query: createMojoQuery(Service.dailytask, 'getCurrentCoinBalance'),
				extraOptions: { retryOnError: true },
			}),
			arrangeStreakRestDays: builder.mutation<
				ServiceResponses[Service.dailytask]['arrangeStreakRestDays'],
				ServiceParams[Service.dailytask]['arrangeStreakRestDays']
			>({
				invalidatesTags: [ApiTag.DailyTask, ApiTag.LearnTracking],
				query: createMojoQuery(Service.dailytask, 'arrangeStreakRestDays'),
			}),
			getCurrentTrackingDate: builder.query<
				ServiceResponses[Service.trackingdate]['getCurrentTrackingDate'],
				ServiceParams[Service.trackingdate]['getCurrentTrackingDate']
			>({
				providesTags: [ApiTag.TrackingDate],
				query: createMojoQuery(Service.trackingdate, 'getCurrentTrackingDate'),
				extraOptions: { retryOnError: true },
			}),
			testIncrementTrackingDate: builder.mutation<
				ServiceResponses[Service.trackingdate]['testIncrementTrackingDate'],
				ServiceParams[Service.trackingdate]['testIncrementTrackingDate']
			>({
				invalidatesTags: [ApiTag.DailyTask, ApiTag.TrackingDate],
				query: createMojoQuery(
					Service.trackingdate,
					'testIncrementTrackingDate',
				),
			}),
			testCompleteActivityDays: builder.mutation<
				ServiceResponses[Service.testautomation]['completeActivityDays'],
				ServiceParams[Service.testautomation]['completeActivityDays']
			>({
				invalidatesTags: [
					ApiTag.DailyTask,
					ApiTag.LearnTracking,
					ApiTag.TrackingDate,
				],
				query: createMojoQuery(Service.testautomation, 'completeActivityDays'),
			}),
			getEpisodeStates: builder.query<
				ServiceResponses[Service.soundtracking]['getEpisodeStates'],
				ServiceParams[Service.soundtracking]['getEpisodeStates']
			>({
				providesTags: [ApiTag.SoundTracking],
				query: createMojoQuery(Service.soundtracking, 'getEpisodeStates'),
				extraOptions: { retryOnError: true },
			}),
			trackEpisodeProgress: builder.mutation<
				ServiceResponses[Service.soundtracking]['trackEpisodeProgress'],
				ServiceParams[Service.soundtracking]['trackEpisodeProgress']
			>({
				invalidatesTags: [ApiTag.SoundTracking],
				query: createMojoQuery(Service.soundtracking, 'trackEpisodeProgress'),
			}),
			getCurrentEfficacyVersion: builder.query<
				ServiceResponses[Service.efficacymeasurement]['getCurrentVersion'],
				ServiceParams[Service.efficacymeasurement]['getCurrentVersion']
			>({
				query: createMojoQuery(
					Service.efficacymeasurement,
					'getCurrentVersion',
				),
				extraOptions: { retryOnError: true },
			}),
			submitEfficacyAnswers: builder.mutation<
				ServiceResponses[Service.efficacymeasurement]['submitAnswers'],
				ServiceParams[Service.efficacymeasurement]['submitAnswers']
			>({
				invalidatesTags: [ApiTag.EfficacyMeasurement],
				query: createMojoQuery(Service.efficacymeasurement, 'submitAnswers'),
			}),
			submitEfficacyAnswersForOnboarding: builder.mutation<
				ServiceResponses[Service.efficacymeasurement]['submitAnswersForOnboarding'],
				ServiceParams[Service.efficacymeasurement]['submitAnswersForOnboarding']
			>({
				invalidatesTags: [ApiTag.EfficacyMeasurement],
				query: createMojoQuery(
					Service.efficacymeasurement,
					'submitAnswersForOnboarding',
				),
			}),
			getEfficacyResults: builder.query<
				ServiceResponses[Service.efficacymeasurement]['getResults'],
				ServiceParams[Service.efficacymeasurement]['getResults']
			>({
				providesTags: [ApiTag.EfficacyMeasurement],
				query: createMojoQuery(Service.efficacymeasurement, 'getResults'),
				extraOptions: { retryOnError: true },
			}),
			getClientSdkConfig: builder.query<
				ServiceResponses[Service.clientsdkconfig]['getConfig'],
				ServiceParams[Service.clientsdkconfig]['getConfig']
			>({
				query: createMojoQuery(Service.clientsdkconfig, 'getConfig', {
					includeAccessToken: false,
				}),
				extraOptions: { retryOnError: true },
			}),
			getIntercomConfig: builder.query<
				ServiceResponses[Service.intercomconfig]['getConfig'],
				ServiceParams[Service.intercomconfig]['getConfig']
			>({
				query: createMojoQuery(Service.intercomconfig, 'getConfig', {
					includeAccessToken: false,
				}),
				extraOptions: { retryOnError: true },
			}),
			getIntercomUserConfig: builder.query<
				ServiceResponses[Service.intercomconfig]['getUserConfig'],
				ServiceParams[Service.intercomconfig]['getUserConfig']
			>({
				query: createMojoQuery(Service.intercomconfig, 'getUserConfig'),
				extraOptions: { retryOnError: true },
			}),
			getCustomerioConfig: builder.query<
				ServiceResponses[Service.customeriosync]['getConfig'],
				ServiceParams[Service.customeriosync]['getConfig']
			>({
				query: createMojoQuery(Service.customeriosync, 'getConfig', {
					includeAccessToken: false,
				}),
				extraOptions: { retryOnError: true },
			}),
			getCustomerioUserConfig: builder.query<
				ServiceResponses[Service.customeriosync]['getUserConfig'],
				ServiceParams[Service.customeriosync]['getUserConfig']
			>({
				query: createMojoQuery(Service.customeriosync, 'getUserConfig'),
				extraOptions: { retryOnError: true },
			}),
			getPricingForUser: builder.query<
				ServiceResponses[Service.subscriptioncheckout]['getPricingForUser'],
				ServiceParams[Service.subscriptioncheckout]['getPricingForUser']
			>({
				keepUnusedDataFor: 0,
				query: createMojoQuery(
					Service.subscriptioncheckout,
					'getPricingForUser',
				),
				extraOptions: { retryOnError: true },
			}),
			createStripeSetupIntent: builder.mutation<
				ServiceResponses[Service.subscriptioncheckout]['createStripeSetupIntent'],
				ServiceParams[Service.subscriptioncheckout]['createStripeSetupIntent']
			>({
				query: createMojoQuery(
					Service.subscriptioncheckout,
					'createStripeSetupIntent',
				),
			}),
			createSubscription: builder.mutation<
				ServiceResponses[Service.subscriptioncheckout]['createSubscription'],
				ServiceParams[Service.subscriptioncheckout]['createSubscription']
			>({
				invalidatesTags: [ApiTag.SubscriptionStatus],
				query: createMojoQuery(
					Service.subscriptioncheckout,
					'createSubscription',
				),
			}),
			getCommunityProfile: builder.query<
				ServiceResponses[Service.communityprofile]['getUser'],
				ServiceParams[Service.communityprofile]['getUser']
			>({
				query: createMojoQuery(Service.communityprofile, 'getUser'),
				extraOptions: { retryOnError: true },
			}),
			getUserIdentifiers: builder.query<
				ServiceResponses[Service.authuseridentifier]['listUserIdentifiers'],
				ServiceParams[Service.authuseridentifier]['listUserIdentifiers']
			>({
				providesTags: [ApiTag.AuthUserIdentifier],
				query: createMojoQuery(
					Service.authuseridentifier,
					'listUserIdentifiers',
				),
				extraOptions: { retryOnError: true },
			}),
			attachEmailUnverified: builder.mutation<
				ServiceResponses[Service.authuseridentifier]['attachEmailUnverified'],
				ServiceParams[Service.authuseridentifier]['attachEmailUnverified']
			>({
				invalidatesTags: [ApiTag.AuthUserIdentifier],
				query: createMojoQuery(
					Service.authuseridentifier,
					'attachEmailUnverified',
				),
			}),
			setMainEmail: builder.mutation<
				ServiceResponses[Service.authuseridentifier]['setMainEmail'],
				ServiceParams[Service.authuseridentifier]['setMainEmail']
			>({
				invalidatesTags: [ApiTag.AuthUserIdentifier],
				query: createMojoQuery(Service.authuseridentifier, 'setMainEmail'),
			}),
			handleDiscourseSso: builder.mutation<
				ServiceResponses[Service.communitysso]['handleLogin'],
				ServiceParams[Service.communitysso]['handleLogin']
			>({
				query: createMojoQuery(Service.communitysso, 'handleLogin'),
			}),
			getSignupAttributionStatus: builder.query<
				ServiceResponses[Service.attribution]['getSignupAttributionStatus'],
				ServiceParams[Service.attribution]['getSignupAttributionStatus']
			>({
				providesTags: [ApiTag.Attribution],
				query: createMojoQuery(
					Service.attribution,
					'getSignupAttributionStatus',
				),
				extraOptions: { retryOnError: true },
			}),
			attributeSignup: builder.mutation<
				ServiceResponses[Service.attribution]['attributeSignup'],
				ServiceParams[Service.attribution]['attributeSignup']
			>({
				invalidatesTags: [ApiTag.Attribution],
				query: createMojoQuery(Service.attribution, 'attributeSignup'),
			}),
			checkCancelationDiscount: builder.query<
				ServiceResponses[Service.subscriptioncancelation]['checkCancelationDiscount'],
				ServiceParams[Service.subscriptioncancelation]['checkCancelationDiscount']
			>({
				providesTags: [ApiTag.CancelationDiscount],
				query: createMojoQuery(
					Service.subscriptioncancelation,
					'checkCancelationDiscount',
				),
			}),
			applyCancelationDiscount: builder.mutation<
				ServiceResponses[Service.subscriptioncancelation]['applyCancelationDiscount'],
				ServiceParams[Service.subscriptioncancelation]['applyCancelationDiscount']
			>({
				invalidatesTags: [
					ApiTag.CancelationDiscount,
					ApiTag.SubscriptionStatus,
				],
				query: createMojoQuery(
					Service.subscriptioncancelation,
					'applyCancelationDiscount',
				),
			}),
			cancelSubscription: builder.mutation<
				ServiceResponses[Service.subscriptioncancelation]['cancelSubscription'],
				ServiceParams[Service.subscriptioncancelation]['cancelSubscription']
			>({
				invalidatesTags: [ApiTag.SubscriptionStatus],
				query: createMojoQuery(
					Service.subscriptioncancelation,
					'cancelSubscription',
				),
			}),
			createTestCred: builder.mutation<
				ServiceResponses[Service.testauthcred]['createToken'],
				ServiceParams[Service.testauthcred]['createToken']
			>({
				extraOptions: { retryOnError: true },
				query: createMojoQuery(Service.testauthcred, 'createToken'),
			}),
			createTestUser: builder.mutation<
				ServiceResponses[Service.testauthuser]['registerUser'],
				ServiceParams[Service.testauthuser]['registerUser']
			>({
				extraOptions: { retryOnError: true },
				query: createMojoQuery(Service.testauthuser, 'registerUser'),
			}),
			createTestSubscription: builder.mutation<
				ServiceResponses[Service.testsubscription]['createSubscription'],
				ServiceParams[Service.testsubscription]['createSubscription']
			>({
				invalidatesTags: [ApiTag.SubscriptionStatus],
				extraOptions: { retryOnError: true },
				query: createMojoQuery(Service.testsubscription, 'createSubscription'),
			}),
			generateGoogleNonce: builder.query<
				ServiceResponses[Service.authcredgoogle]['generateNonce'],
				ServiceParams[Service.authcredgoogle]['generateNonce']
			>({
				query: createMojoQuery(Service.authcredgoogle, 'generateNonce'),
			}),
			generateAppleNonce: builder.query<
				ServiceResponses[Service.authcredapple]['generateNonce'],
				ServiceParams[Service.authcredapple]['generateNonce']
			>({
				query: createMojoQuery(Service.authcredapple, 'generateNonce'),
			}),
			exchangeGoogleToken: builder.query<
				ServiceResponses[Service.authcredgoogle]['exchangeToken'],
				ServiceParams[Service.authcredgoogle]['exchangeToken']
			>({
				query: createMojoQuery(Service.authcredgoogle, 'exchangeToken'),
			}),
			exchangeAppleToken: builder.query<
				ServiceResponses[Service.authcredapple]['exchangeToken'],
				ServiceParams[Service.authcredapple]['exchangeToken']
			>({
				query: createMojoQuery(Service.authcredapple, 'exchangeToken'),
			}),
			sendMagicLink: builder.mutation<
				ServiceResponses[Service.authcredemail]['sendMagicLink'],
				ServiceParams[Service.authcredemail]['sendMagicLink']
			>({
				query: createMojoQuery(Service.authcredemail, 'sendMagicLink'),
			}),
			exchangeMagicLinkToken: builder.query<
				ServiceResponses[Service.authcredemail]['exchangeToken'],
				ServiceParams[Service.authcredemail]['exchangeToken']
			>({
				query: createMojoQuery(Service.authcredemail, 'exchangeToken'),
			}),
			checkMagicLinkApproverStatus: builder.query<
				ServiceResponses[Service.authcredemail]['approverGetStatus'],
				ServiceParams[Service.authcredemail]['approverGetStatus']
			>({
				query: createMojoQuery(Service.authcredemail, 'approverGetStatus'),
			}),
			approverApproveMagicLink: builder.mutation<
				ServiceResponses[Service.authcredemail]['approverApprove'],
				ServiceParams[Service.authcredemail]['approverApprove']
			>({
				query: createMojoQuery(Service.authcredemail, 'approverApprove'),
			}),
			approverRejectMagicLink: builder.mutation<
				ServiceResponses[Service.authcredemail]['approverReject'],
				ServiceParams[Service.authcredemail]['approverReject']
			>({
				query: createMojoQuery(Service.authcredemail, 'approverReject'),
			}),
			requesterGetMagicLinkStatus: builder.query<
				ServiceResponses[Service.authcredemail]['requesterGetStatus'],
				ServiceParams[Service.authcredemail]['requesterGetStatus']
			>({
				query: createMojoQuery(Service.authcredemail, 'requesterGetStatus'),
			}),
			requesterExchangeMagicLinkCode: builder.query<
				ServiceResponses[Service.authcredemail]['requesterExchangeCode'],
				ServiceParams[Service.authcredemail]['requesterExchangeCode']
			>({
				query: createMojoQuery(Service.authcredemail, 'requesterExchangeCode'),
			}),
			login: builder.mutation<
				ServiceResponses[Service.authuser]['loginUser'],
				ServiceParams[Service.authuser]['loginUser']
			>({
				query: createMojoQuery(Service.authuser, 'loginUser'),
			}),
			register: builder.mutation<
				ServiceResponses[Service.authuser]['registerUserWithCredToken'],
				ServiceParams[Service.authuser]['registerUserWithCredToken']
			>({
				query: createMojoQuery(Service.authuser, 'registerUserWithCredToken'),
			}),
			registerUnverified: builder.mutation<
				ServiceResponses[Service.authuser]['registerUserUnverified'],
				ServiceParams[Service.authuser]['registerUserUnverified']
			>({
				query: createMojoQuery(Service.authuser, 'registerUserUnverified'),
			}),
			setExperimentUserData: builder.mutation<
				ServiceResponses[Service.experimentuserdata]['setItem'],
				ServiceParams[Service.experimentuserdata]['setItem']
			>({
				query: createMojoQuery(Service.experimentuserdata, 'setItem'),
			}),
			getRevenuecatConfig: builder.query<
				ServiceResponses[Service.inapppurchase]['getRevenuecatConfig'],
				ServiceParams[Service.inapppurchase]['getRevenuecatConfig']
			>({
				query: createMojoQuery(Service.inapppurchase, 'getRevenuecatConfig'),
				extraOptions: { retryOnError: true },
			}),
			getRevenuecatUserEntitlements: builder.query<
				ServiceResponses[Service.inapppurchase]['getRevenuecatUserEntitlements'],
				ServiceParams[Service.inapppurchase]['getRevenuecatUserEntitlements']
			>({
				providesTags: [ApiTag.InAppPurchase, ApiTag.SubscriptionStatus],
				query: createMojoQuery(
					Service.inapppurchase,
					'getRevenuecatUserEntitlements',
				),
				extraOptions: { retryOnError: true },
			}),
			getPollStats: builder.query<
				ServiceResponses[Service.poll]['getPollStats'],
				ServiceParams[Service.poll]['getPollStats']
			>({
				providesTags: (_, __, args) => [{ type: ApiTag.Poll, id: args.pollId }],
				query: createMojoQuery(Service.poll, 'getPollStats'),
				extraOptions: { retryOnError: true },
			}),
			recordPollResponse: builder.mutation<
				ServiceResponses[Service.poll]['recordPollResponse'],
				ServiceParams[Service.poll]['recordPollResponse']
			>({
				invalidatesTags: (_, __, args) => [
					{ type: ApiTag.Poll, id: 'LIST' },
					{ type: ApiTag.Poll, id: args.pollId },
				],
				query: createMojoQuery(Service.poll, 'recordPollResponse'),
			}),
			listPollResponses: builder.query<
				ServiceResponses[Service.poll]['listPollResponses'],
				ServiceParams[Service.poll]['listPollResponses']
			>({
				providesTags: [{ type: ApiTag.Poll, id: 'LIST' }],
				query: createMojoQuery(Service.poll, 'listPollResponses'),
				extraOptions: { retryOnError: true },
			}),
			startSelfDeletion: builder.mutation<
				ServiceResponses[Service.authuserdeletion]['startSelfDeletion'],
				ServiceParams[Service.authuserdeletion]['startSelfDeletion']
			>({
				query: createMojoQuery(Service.authuserdeletion, 'startSelfDeletion'),
			}),
			searchItoLanggraphThreads: builder.query<
				ServiceResponses[Service.itochat]['searchLanggraphThreads'],
				ServiceParams[Service.itochat]['searchLanggraphThreads']
			>({
				providesTags: [ApiTag.ItoChat],
				query: createMojoQuery(Service.itochat, 'searchLanggraphThreads'),
				extraOptions: { retryOnError: true },
			}),
			createItoLanggraphThread: builder.mutation<
				ServiceResponses[Service.itochat]['createLanggraphThread'],
				ServiceParams[Service.itochat]['createLanggraphThread']
			>({
				invalidatesTags: [ApiTag.ItoChat],
				query: createMojoQuery(Service.itochat, 'createLanggraphThread'),
			}),
			runItoLanggraphThread: builder.mutation<
				ServiceResponses[Service.itochat]['runLanggraphThread'],
				ServiceParams[Service.itochat]['runLanggraphThread']
			>({
				query: createMojoQuery(Service.itochat, 'runLanggraphThread', {
					timeout: 3 * 60000,
				}),
				async onQueryStarted(payload, { dispatch, queryFulfilled }) {
					// Get the current timestamp for the optimistic update as a temporary message id
					const timestamp = new Date().toISOString();

					// Create an optimistic patch to update the thread messages
					const patchResult = dispatch(
						apiSlice.util.updateQueryData(
							'searchItoLanggraphThreads',
							{ userId: payload.userId },
							(draft) => {
								const thread = draft.find(
									(t) => t.threadId === payload.threadId,
								);
								if (thread && thread.values) {
									if (
										payload.input?.messages &&
										Array.isArray(payload.input.messages) &&
										payload.input.messages[0] &&
										isChatMessage(payload.input.messages[0])
									) {
										const message = payload.input.messages[0];
										thread.values.messages = [
											...(thread.values.messages || []),
											{
												id: `temp-${timestamp}`,
												content: message.content,
												type: message.role,
												additionalKwargs: {},
												toolCalls: [],
												toolCallChunks: [],
											} as RunMessage,
										];
									}
								}
							},
						),
					);

					try {
						const { data } = await queryFulfilled;

						// Update with the real response data
						dispatch(
							apiSlice.util.updateQueryData(
								'searchItoLanggraphThreads',
								{ userId: payload.userId },
								(draft) => {
									const thread = draft.find(
										(t) => t.threadId === payload.threadId,
									);
									if (thread && thread.values) {
										// Update the thread messages with the actual response
										thread.values.messages = data.messages;
									}
								},
							),
						);
					} catch {
						// If the request fails, undo the optimistic update
						patchResult.undo();
						// And invalidate ItoChat to refetch searchItoLanggraphThreads
						dispatch(apiSlice.util.invalidateTags([ApiTag.ItoChat]));
					}
				},
			}),
			getItoSession: builder.query<
				ServiceResponses[Service.itoinnercritic]['getSession'],
				ServiceParams[Service.itoinnercritic]['getSession']
			>({
				providesTags: [ApiTag.ItoSession],
				query: createMojoQuery(Service.itoinnercritic, 'getSession'),
				extraOptions: { retryOnError: true },
			}),
			startItoSession: builder.query<
				ServiceResponses[Service.itoinnercritic]['startSession'],
				ServiceParams[Service.itoinnercritic]['startSession']
			>({
				providesTags: [ApiTag.ItoSession],
				query: createMojoQuery(Service.itoinnercritic, 'startSession'),
				extraOptions: { retryOnError: true },
			}),
			processItoSessionResponse: builder.mutation<
				ServiceResponses[Service.itoinnercritic]['processSessionResponse'],
				ServiceParams[Service.itoinnercritic]['processSessionResponse']
			>({
				query: createMojoQuery(
					Service.itoinnercritic,
					'processSessionResponse',
					{ timeout: 30000 },
				),
			}),
			itoVisualizeAnswers: builder.query<
				ServiceResponses[Service.itoinnercritic]['visualizeAnswers'],
				ServiceParams[Service.itoinnercritic]['visualizeAnswers']
			>({
				providesTags: [ApiTag.ItoInnerCritic],
				query: createMojoQuery(Service.itoinnercritic, 'visualizeAnswers', {
					timeout: 60000,
				}),
				extraOptions: { retryOnError: true },
			}),
			createCommunityTopic: builder.mutation<
				ServiceResponses[Service.communitycontent]['createTopic'],
				ServiceParams[Service.communitycontent]['createTopic']
			>({
				invalidatesTags: [{ type: ApiTag.CommunityContent, id: 'TOPICS_LIST' }],
				query: createMojoQuery(Service.communitycontent, 'createTopic'),
			}),
			replyCommunityTopic: builder.mutation<
				ServiceResponses[Service.communitycontent]['createReply'],
				ServiceParams[Service.communitycontent]['createReply']
			>({
				invalidatesTags: (_, __, args) => [
					{ type: ApiTag.CommunityContent, id: 'TOPICS_LIST' },
					{ type: ApiTag.CommunityContent, id: `topic-${args.topicId}` },
				],
				query: createMojoQuery(Service.communitycontent, 'createReply'),
			}),
			listCommunityCategories: builder.query<
				ServiceResponses[Service.communitycontent]['listCategories'],
				ServiceParams[Service.communitycontent]['listCategories']
			>({
				query: createMojoQuery(Service.communitycontent, 'listCategories'),
				extraOptions: { retryOnError: true },
			}),
			listCommunityTopics: builder.query<
				ServiceResponses[Service.communitycontent]['listTopics'],
				ServiceParams[Service.communitycontent]['listTopics']
			>({
				providesTags: [{ type: ApiTag.CommunityContent, id: 'TOPICS_LIST' }],
				query: createMojoQuery(Service.communitycontent, 'listTopics'),
				extraOptions: { retryOnError: true },
			}),
			getCommunityTopic: builder.query<
				ServiceResponses[Service.communitycontent]['getTopic'],
				ServiceParams[Service.communitycontent]['getTopic']
			>({
				providesTags: (_, __, args) => [
					{ type: ApiTag.CommunityContent, id: `topic-${args.topicId}` },
				],
				query: createMojoQuery(Service.communitycontent, 'getTopic'),
				extraOptions: { retryOnError: true },
			}),
			listCommunityReplies: builder.query<
				ServiceResponses[Service.communitycontent]['listReplies'],
				ServiceParams[Service.communitycontent]['listReplies']
			>({
				providesTags: (_, __, args) => [
					{ type: ApiTag.CommunityContent, id: `topic-${args.topicId}` },
				],
				query: createMojoQuery(Service.communitycontent, 'listReplies'),
				extraOptions: { retryOnError: true },
			}),
			listCommunityReactionStats: builder.query<
				ServiceResponses[Service.communityreaction]['listReactionStats'],
				ServiceParams[Service.communityreaction]['listReactionStats']
			>({
				providesTags: [ApiTag.CommunityReaction],
				query: createMojoQuery(Service.communityreaction, 'listReactionStats'),
				extraOptions: { retryOnError: true },
			}),
			listCommunityUserReactions: builder.query<
				ServiceResponses[Service.communityreaction]['listUserReactions'],
				ServiceParams[Service.communityreaction]['listUserReactions']
			>({
				providesTags: [ApiTag.CommunityReaction],
				query: createMojoQuery(Service.communityreaction, 'listUserReactions'),
				extraOptions: { retryOnError: true },
			}),
			toggleCommunityUserReaction: builder.mutation<
				ServiceResponses[Service.communityreaction]['toggleUserReaction'],
				ServiceParams[Service.communityreaction]['toggleUserReaction']
			>({
				invalidatesTags: [ApiTag.CommunityReaction],
				query: createMojoQuery(Service.communityreaction, 'toggleUserReaction'),
				async onQueryStarted(payload, { dispatch, getState, queryFulfilled }) {
					const patchCommunityReactionStats = dispatch(
						apiSlice.util.updateQueryData(
							'listCommunityReactionStats',
							undefined,
							(draft) => {
								// Find the item in the list of community reactions
								const item = draft.find(
									(d) => d.contentIdentifier === payload.contentIdentifier,
								);

								// Now we need to find the user stats for the content to see if they are liking or unliking the content
								const userStatsSelector =
									apiSlice.endpoints.listCommunityUserReactions.select({
										userId: payload.userId,
									});
								const userStats = userStatsSelector(getState());

								// If the user stats are available, we need to find the content that the user is liking
								const userContent = userStats?.data?.find(
									(i) => i.contentIdentifier === payload.contentIdentifier,
								);

								const isLiked = Boolean(userContent);

								if (!item) {
									// If there is no item, it means that the content has not been liked before so we should add it to the list
									draft.push({ ...payload, count: isLiked ? 0 : 1 });
								} else if (item.count !== undefined) {
									isLiked ? item.count-- : item.count++;
								}
							},
						),
					);

					const patchUserCommunityReactionStats = dispatch(
						apiSlice.util.updateQueryData(
							'listCommunityUserReactions',
							{ userId: payload.userId },
							(draft) => {
								// Find the item in the list of user reactions
								const itemIndex = draft.findIndex(
									(d) => d.contentIdentifier === payload.contentIdentifier,
								);

								if (itemIndex === -1) {
									// If no item is found, it means that the user has not liked the content before
									draft.push({ ...payload });
								} else {
									// If the item is found, it means that the user has liked the content before
									draft.splice(itemIndex, 1);
								}
							},
						),
					);
					try {
						await queryFulfilled;
					} catch {
						patchCommunityReactionStats.undo();
						patchUserCommunityReactionStats.undo();
					}
				},
			}),
			listItoMemories: builder.query<
				ServiceResponses[Service.itochat]['listMemories'],
				ServiceParams[Service.itochat]['listMemories']
			>({
				providesTags: [ApiTag.ItoMemories],
				query: createMojoQuery(Service.itochat, 'listMemories'),
				extraOptions: { retryOnError: true },
			}),
			upsertItoMemory: builder.mutation<
				ServiceResponses[Service.itochat]['upsertMemory'],
				ServiceParams[Service.itochat]['upsertMemory']
			>({
				invalidatesTags: [ApiTag.ItoMemories],
				query: createMojoQuery(Service.itochat, 'upsertMemory'),
			}),
			deleteItoMemory: builder.mutation<
				ServiceResponses[Service.itochat]['deleteMemory'],
				ServiceParams[Service.itochat]['deleteMemory']
			>({
				invalidatesTags: [ApiTag.ItoMemories],
				query: createMojoQuery(Service.itochat, 'deleteMemory'),
			}),
		};
	},
});

export default apiSlice;

function createMojoQuery<
	ServiceKey extends Service,
	Endpoint extends keyof ServiceParams[ServiceKey] &
		keyof ServiceResponses[ServiceKey],
	Params extends ServiceParams[ServiceKey][Endpoint],
>(service: ServiceKey, endpoint: Endpoint, options?: RequestOptions) {
	return (params?: Params) => {
		return {
			provider: QueryProvider.Mojo,
			service,
			endpoint,
			params,
			options,
		} as MojoQueryArgs as QueryArgs;
	};
}

function createSanityQuery<Params extends any>(query: string) {
	return (params?: Params) => {
		return {
			provider: QueryProvider.Sanity,
			query,
			params,
		} as SanityQueryArgs as QueryArgs;
	};
}

interface ChatMessage {
	content: string;
	role: string;
}

function isChatMessage(message: unknown): message is ChatMessage {
	return (
		typeof message === 'object' &&
		message !== null &&
		'content' in message &&
		'role' in message &&
		typeof (message as ChatMessage).content === 'string' &&
		typeof (message as ChatMessage).role === 'string'
	);
}
