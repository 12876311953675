import { Temporal } from '@js-temporal/polyfill';
import { skipToken } from '@reduxjs/toolkit/query';
import { useMemo } from 'react';

import useEndpointQuery from '../../../hooks/queries/useEndpointQuery';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useLocalization } from '../../../hooks/useLocalization';
import { selectUserId } from '../../session';
import api from '..';

const useCurrentTrackingDate = () => {
	const userId = useAppSelector(selectUserId);
	const { timezone } = useLocalization();

	const { data } = useEndpointQuery(
		api.endpoints.getCurrentTrackingDate.useQuery(
			userId
				? { userIdentifier: { type: 'user', value: userId }, timezone }
				: skipToken,
			{ refetchOnFocus: true },
		),
	);

	return useMemo(
		() => data && Temporal.PlainDate.from(data.trackingDate),
		[data],
	);
};

export default useCurrentTrackingDate;
