import { skipToken } from '@reduxjs/toolkit/query';

import { HandleableErrorCode } from '../../../errors/HandleableError';
import useEndpointQuery from '../../../hooks/queries/useEndpointQuery';
import useHandleImpersonationAccessDenied from '../../../hooks/queries/useHandleImpersonationAccessDenied';
import useBackendUserIdentifier from '../../../hooks/useBackendUserIdentifier';
import api from '..';

const usePollResponses = (includePreviousResponses: boolean = false) => {
	const userIdentifier = useBackendUserIdentifier();

	const res = useEndpointQuery(
		api.endpoints.listPollResponses.useQuery(
			(userIdentifier && {
				userIdentifier,
				includePreviousResponses,
			}) ??
				skipToken,
		),
		{
			customHandleErrors: [HandleableErrorCode.access_denied],
		},
	);

	useHandleImpersonationAccessDenied(res.error);

	return res;
};

export default usePollResponses;
