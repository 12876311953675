import { createSlice } from '@reduxjs/toolkit';
import { BackendConfig } from '@wearemojo/api-client';
import { UIFlags } from '@wearemojo/ui-components';

import type { LogType } from '../utils/logging';
import { AppState, DebugFlags, DevState, SanityConfig } from './types';

const initialState: DevState = {
	backendConfigOverride: undefined,
	uiFlagOverrides: {},
	debugFlags: {},
	cmsContent: null,
	cmsContentAttribute: null,
	showStaffKnob: false,
	itoDevMode: false,
	enabledLogs: {
		analytics: __DEV__,
		info: __DEV__,
		warning: __DEV__,
		error: __DEV__,
	},
	sanityConfig: null,
	homescreenChatPrompt: undefined,
	homescreenSafetyPrompt: undefined,
};

const devSlice = createSlice({
	name: 'dev',
	initialState,
	reducers: {
		setBackendConfigOverride: (
			state,
			action: { payload: BackendConfig | undefined },
		) => {
			state.backendConfigOverride = action.payload;
		},
		setUiFlagOverrides: (state, action: { payload: Partial<UIFlags> }) => {
			state.uiFlagOverrides = action.payload;
		},
		toggleDebugFlag: (
			state,
			{ payload: flag }: { payload: keyof DebugFlags },
		) => {
			state.debugFlags[flag] = !state.debugFlags[flag];
		},
		setDevCmsContent: (state, action: { payload: string | null }) => {
			state.cmsContent = action.payload;
		},
		setDevCmsContentAttribute: (state, action: { payload: string | null }) => {
			state.cmsContentAttribute = action.payload;
		},
		setSanityConfig: (state, action: { payload: SanityConfig }) => {
			state.sanityConfig = action.payload;
		},
		setHomescreenChatPrompt: (
			state,
			action: { payload: string | undefined },
		) => {
			state.homescreenChatPrompt = action.payload;
		},
		setHomescreenSafetyPrompt: (
			state,
			action: { payload: string | undefined },
		) => {
			state.homescreenSafetyPrompt = action.payload;
		},
		showStaffKnob: (state) => {
			state.showStaffKnob = true;
		},
		toggleItoDevMode: (state) => {
			state.itoDevMode = !state.itoDevMode;
		},
		toggleLogsEnabled: (state, action: { payload: LogType }) => {
			state.enabledLogs[action.payload] = !state.enabledLogs[action.payload];
		},
	},
});

export const {
	setBackendConfigOverride,
	setUiFlagOverrides,
	toggleDebugFlag,
	setDevCmsContent,
	setDevCmsContentAttribute,
	setSanityConfig,
	setHomescreenChatPrompt,
	setHomescreenSafetyPrompt,
	showStaffKnob,
	toggleItoDevMode,
	toggleLogsEnabled,
} = devSlice.actions;
export const selectBackendConfigOverride = (state: AppState) =>
	state.dev.backendConfigOverride;
export const selectUiFlagOverrides = (state: AppState) =>
	state.dev.uiFlagOverrides;
export const selectDebugFlags = (state: AppState) => state.dev.debugFlags;
export const selectDevCmsContent = (state: AppState) => state.dev.cmsContent;
export const selectDevCmsContentAttribute = (state: AppState) =>
	state.dev.cmsContentAttribute;
export const selectEnabledLogs = (state: AppState) => state.dev.enabledLogs;
export const selectItoDevMode = (state: AppState) => state.dev.itoDevMode;
export const selectSanityConfig = (state: AppState) => state.dev.sanityConfig;
export const selectHomescreenChatPrompt = (state: AppState) =>
	state.dev.homescreenChatPrompt;
export const selectHomescreenSafetyPrompt = (state: AppState) =>
	state.dev.homescreenSafetyPrompt;
export default devSlice;
