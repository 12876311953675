import { FlowType, FragmentFlowsType } from '@wearemojo/sanity-schema';
import { createContext, ReactNode, useContext, useMemo } from 'react';

import { FragmentType } from '../content/FragmentType';
import { useFragments } from '../hooks/content/useFragment';
import useEndpointQuery from '../hooks/queries/useEndpointQuery';
import api from '../store/api';
import { resolveSanityDocuments } from '../utils/resolveSanityDocuments';
import FlowImageBackgroundProvider from './FlowImageBackgroundProvider';
import FlowProgressProvider from './FlowProgressProvider';

type ContextProps = {
	data: {
		allFlows: FlowType[] | undefined;
		registrationPrefaceFlow?: FlowType;
		checkoutPrefaceFlow?: FlowType;
		welcomeFlow?: FlowType;
	};
	isLoading: boolean;
};

const defaultFlow = {
	allFlows: undefined,
	registrationPrefaceFlow: undefined,
	checkoutPrefaceFlow: undefined,
	welcomeFlow: undefined,
};

const FlowContext = createContext<ContextProps>({
	data: defaultFlow,
	isLoading: true,
});

type Props = {
	children: ReactNode;
};
const FlowProvider = ({ children }: Props) => {
	const { data: fragments, isLoading: isLoadingFragments } = useFragments();
	const { data: expressionDocuments, isLoading: isLoadingExpressions } =
		useEndpointQuery(api.endpoints.getExpressionDocuments.useQuery());
	const { data: flowDocuments, isLoading: isLoadingFlows } = useEndpointQuery(
		api.endpoints.getFlowDocuments.useQuery(),
	);

	const isLoading =
		isLoadingFragments || isLoadingExpressions || isLoadingFlows;

	const context = useMemo(() => {
		if (!fragments || !expressionDocuments || !flowDocuments) {
			return {
				data: defaultFlow,
				isLoading,
			};
		}

		const resolvedDocuments = resolveSanityDocuments([
			...expressionDocuments,
			...flowDocuments,
		]);

		const fragment = fragments.find(
			({ _type }) => _type === FragmentType.Flows,
		) as FragmentFlowsType | undefined;

		if (!fragment) throw new Error('Flows fragment not found');

		const allFlows = Array.from(resolvedDocuments.values()).filter(
			({ _type }) => _type === 'Flow',
		) as FlowType[];

		const getFlow = ({ __ref }: { __ref: string }) =>
			allFlows.find(({ _id }) => _id === __ref);

		return {
			data: {
				allFlows,
				registrationPrefaceFlow: getFlow(fragment.registrationPrefaceFlow),
				checkoutPrefaceFlow: getFlow(fragment.checkoutPrefaceFlow),
				welcomeFlow: getFlow(fragment.welcomeFlow),
			},
			isLoading: false,
		};
	}, [expressionDocuments, flowDocuments, fragments, isLoading]);

	return (
		<FlowContext.Provider value={context}>
			<FlowImageBackgroundProvider>
				<FlowProgressProvider>{children}</FlowProgressProvider>
			</FlowImageBackgroundProvider>
		</FlowContext.Provider>
	);
};

export const useFlowContext = () => {
	const context = useContext(FlowContext);

	if (context === undefined) {
		throw new Error('useFlowContext must be used within a FlowProvider');
	}
	return context;
};

export default FlowProvider;
