import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

type ContextProps = {
	hasBackgroundImage: boolean;
	setFlowBackgroundImage: (hasBackground: boolean) => void;
};

const FlowImageBackgroundContext = createContext<ContextProps | undefined>(
	undefined,
);

type Props = {
	children: ReactNode;
};

const FlowImageBackgroundProvider = ({ children }: Props) => {
	const [hasBackgroundImage, setHasBackgroundImage] = useState(false);

	const context = useMemo(() => {
		return {
			hasBackgroundImage,
			setFlowBackgroundImage: setHasBackgroundImage,
		};
	}, [hasBackgroundImage]);

	return (
		<FlowImageBackgroundContext.Provider value={context}>
			{children}
		</FlowImageBackgroundContext.Provider>
	);
};

export const useFlowImageBackgroundContext = () => {
	const context = useContext(FlowImageBackgroundContext);
	return context;
};

export default FlowImageBackgroundProvider;
