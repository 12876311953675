import {
	createContext,
	ReactNode,
	useCallback,
	useContext,
	useMemo,
	useRef,
	useState,
} from 'react';

type StepsProgress = {
	stepIndex: number;
	stepsLength: number;
	hideProgressBar?: boolean;
};

type ContextProps = {
	percentage: number | null;
	setFlowProgress: ({
		stepIndex,
		stepsLength,
		hideProgressBar,
	}: StepsProgress) => void;
};

const FlowProgressContext = createContext<ContextProps | undefined>(undefined);

type Props = {
	children: ReactNode;
};

const FlowProgressProvider = ({ children }: Props) => {
	const [percentage, setPercentage] = useState<number>(0);
	const [hideProgressBar, setHideProgressBar] = useState(false);
	const previous = useRef<StepsProgress & { percentage: number }>({
		stepIndex: 0,
		stepsLength: 1,
		percentage: 0,
	});

	const setFlowProgress = useCallback(
		({
			stepIndex,
			stepsLength,
			hideProgressBar: shouldHideProgressBar,
		}: StepsProgress) => {
			setHideProgressBar(!!shouldHideProgressBar);

			const progress = stepIndex / (stepsLength - 1);

			if (__DEV__) {
				console.log(
					`Steps: ${stepIndex}/${stepsLength - 1} => ${(progress * 100).toFixed(2)}%`,
				);
			}

			/**
			 * The length of the flows are dynamic, and they change based on users answers
			 * to polls. This could mean that as user chooses an answer that adds 10 extra
			 * screens to the flow, and, if that happens, the simple division of step index
			 * by steps length would result in a smaller progress than before. So, to avoid,
			 * giving the impression to a user that they went backwards, we have the logic
			 * below to manually advance 1% just in case.
			 */
			const hasAdvancedButNotProgressed =
				(stepIndex > previous.current.stepIndex ||
					stepsLength > previous.current.stepsLength) &&
				progress <= previous.current.percentage;

			const newProgress = hasAdvancedButNotProgressed
				? Math.min(previous.current.percentage + 0.01, 0.99) // advance by 1%, but never more than to 99%
				: progress;

			const clampedProgress = Math.min(Math.max(0, newProgress), 1);
			setPercentage(clampedProgress);
			previous.current = {
				stepIndex,
				stepsLength,
				percentage: clampedProgress,
			};

			if (__DEV__) {
				console.log(`Progress bar: ${(clampedProgress * 100).toFixed(2)}%`);
			}
		},
		[],
	);

	const context = useMemo(() => {
		return {
			percentage: hideProgressBar ? null : percentage,
			setFlowProgress,
		};
	}, [percentage, setFlowProgress, hideProgressBar]);

	return (
		<FlowProgressContext.Provider value={context}>
			{children}
		</FlowProgressContext.Provider>
	);
};

export const useFlowProgressContext = () => {
	const context = useContext(FlowProgressContext);
	return context;
};

export default FlowProgressProvider;
